
import { Status } from "@/util/enums";
import {
  IonBadge,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/vue";
import {
  boatOutline,
  homeOutline,
  informationCircleSharp,
  logOutOutline,
  readerOutline,
  downloadOutline,
} from "ionicons/icons";
import { computed, defineComponent, onBeforeMount } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "TabsFrame",
  components: {
    IonBadge,
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    const store = useStore();

    const onboardCrew = computed(
      () => store.getters.crewByStatus(Status.ONBOARD).length
    );
    const offboardCrew = computed(
      () => store.getters.crewByStatus(Status.OFFBOARD).length
    );
    const indockCrew = computed(
      () => store.getters.crewByStatus(Status.INDOCK).length
    );
    const onleaveCrew = computed(
      () => store.getters.crewByStatus(Status.ONLEAVE).length
    );

    function tabSelection(tab: string) {
      store.dispatch("activateTab", tab);
    }

    function allOnboard() {
      const total =
        onboardCrew.value +
        offboardCrew.value +
        indockCrew.value +
        onleaveCrew.value;
      return onboardCrew.value === total - onleaveCrew.value;
    }

    onBeforeMount(() => {
      const tab = `${window.location.href}`.split("/")[4];

      if (tab !== "log") tabSelection(tab);
    });

    return {
      boatOutline,
      homeOutline,
      informationCircleSharp,
      logOutOutline,
      readerOutline,
      downloadOutline,
      onboardCrew,
      offboardCrew,
      indockCrew,
      onleaveCrew,
      tabSelection,
      allOnboard,
    };
  },
});
