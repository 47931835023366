// export enum Tab {
//   ONBOARD = 'On Board',
//   OFFBOARD = 'Off Board',
//   ONLEAVE = 'On Leave',
//   LOG = 'Log',
// }

export enum Status {
  ONBOARD = "onboard",
  OFFBOARD = "offboard",
  INDOCK = "indock",
  ONLEAVE = "onleave",
}
