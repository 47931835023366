import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "onboard",
                href: "/onboard",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tabSelection('onboard')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.boatOutline }, null, 8, ["icon"]),
                  (_ctx.allOnboard())
                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 0,
                        color: "success"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.onboardCrew), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 1,
                        color: "danger"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.onboardCrew), 1)
                        ]),
                        _: 1
                      })),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("On Board")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "offboard",
                href: "/offboard",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.tabSelection('offboard')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.logOutOutline }, null, 8, ["icon"]),
                  (_ctx.offboardCrew === 0)
                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 0,
                        color: "success"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.offboardCrew), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 1,
                        color: "danger"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.offboardCrew), 1)
                        ]),
                        _: 1
                      })),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Off Board")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "indock",
                href: "/indock",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.tabSelection('indock')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.downloadOutline }, null, 8, ["icon"]),
                  (_ctx.indockCrew === 0)
                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 0,
                        color: "success"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.indockCrew), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 1,
                        color: "danger"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.indockCrew), 1)
                        ]),
                        _: 1
                      })),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("In Dock")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "onleave",
                href: "/onleave",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.tabSelection('onleave')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.homeOutline }, null, 8, ["icon"]),
                  (_ctx.onleaveCrew === 0)
                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 0,
                        color: "success"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.onleaveCrew), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 1,
                        color: "danger"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.onleaveCrew), 1)
                        ]),
                        _: 1
                      })),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("On Leave")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "log",
                href: "/log",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.tabSelection('log')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.readerOutline }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Log")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}